import Vue from "vue";
import * as Api from "../../../axios-generated/realestate/";
import * as ApiBo from "../../../axios-generated/backoffice/";
// @ts-ignore
import axios from "@axios";

const REALESTATE_API = new Api.PropertiesApi(
  undefined,
  Vue.prototype.$config.VUE_APP_REALESTATE_API_BASE_URL,
  axios
);

const FLOWS_API = new ApiBo.FlowsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSuggestAddress(ctx, { query }) {
      return new Promise((resolve, reject) => {
        axios
          .get(Vue.prototype.$config.VUE_APP_GOUV_API_GEO_SEARCH, {
            params: { q: query },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    listProperties(ctx, searchProperties) {
      return new Promise((resolve, reject) => {
        REALESTATE_API.listProperties(
          searchProperties.filter_enable,
          searchProperties.page_size,
          searchProperties.page,
          searchProperties.order_by,
          searchProperties.sort_dir_desc,
          searchProperties.developer_id,
          searchProperties.service,
          searchProperties.owner_type,
          searchProperties.type_parents,
          searchProperties.flow_origin,
          searchProperties.age,
          searchProperties.nb_rooms,
          searchProperties.min_price,
          searchProperties.max_price,
          searchProperties.min_area,
          searchProperties.max_area,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          searchProperties.geo_distances,
          searchProperties.geo_bounding_box
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    listClusters(ctx, searchPropertiesOnMap) {
      return new Promise((resolve, reject) => {
        REALESTATE_API.listPropertyClusters(
          searchPropertiesOnMap.filter_enable,
          searchPropertiesOnMap.developer_id,
          searchPropertiesOnMap.service,
          searchPropertiesOnMap.owner_type,
          searchPropertiesOnMap.type_parents,
          searchPropertiesOnMap.flow_origin,
          searchPropertiesOnMap.age,
          searchPropertiesOnMap.nb_rooms,
          searchPropertiesOnMap.min_price,
          searchPropertiesOnMap.max_price,
          searchPropertiesOnMap.min_area,
          searchPropertiesOnMap.max_area,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          searchPropertiesOnMap.geo_bounding_box
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getPropertyByID(ctx, id: number) {
      return new Promise((resolve, reject) => {
        REALESTATE_API.getProperty(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getPropertiesByIDs(ctx, ids) {
      return new Promise((resolve, reject) => {
        REALESTATE_API.findSeveralProperties(ids)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // FLOWS
    fetchFlowsOrigins(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        FLOWS_API.findFlowsOrigins(queryParams.typeOffers, queryParams.status)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
