<template>
  <div class="itemgrid-application">
    <section class="app-itemgrid-details">
      <b-card no-body class="mb-0">
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-img
                  v-if="property.images && property.images.length > 0"
                  :src="property.images[0]"
                  :alt="`Image of ${property.title}`"
                  @error="imageLoadError()"
                  class="product-img"
                  fluid
                />
                <b-img
                  v-else
                  :src="$config.VUE_APP_IMG_NOT_AVAILABLE"
                  :alt="`Image of ${property.title}`"
                  class="product-img"
                  fluid
                />
              </div>
            </b-col>

            <b-col cols="12" md="9">
              <h4>
                {{ property.title }}
                <small class="text-muted"> ID: {{ property.id }}</small>
              </h4>

              <h6>
                {{ property.address.street }}
                {{ property.address.zip }}
                {{ property.address.city }}
              </h6>

              <b-card-text class="item-company mb-0" v-if="property.contact">
                <span>proposé par</span>
                <b-link class="company-name">
                  {{ property.contact.name }}
                </b-link>
              </b-card-text>

              <hr />

              <div class="itemgrid-details-price d-flex flex-wrap">
                <h4 class="item-price mr-1">
                  {{ currency(property.price) }}
                </h4>
                <ul
                  class="unstyled-list list-inline pl-1 border-left"
                  v-if="property.area"
                >
                  {{
                    currency(property.price / property.area.area)
                  }}/m²
                </ul>
              </div>

              <b-row>
                <b-col md="3" v-if="property.area.area">
                  <ul class="itemgrid-features list-unstyled my-50">
                    <li>
                      <feather-icon icon="MaximizeIcon" />
                      <span>{{ property.area.area }} m²</span>
                    </li>
                  </ul>
                </b-col>
                <b-col md="3" v-if="property.rooms.nb_rooms">
                  <ul class="itemgrid-features list-unstyled my-50">
                    <li>
                      <feather-icon icon="GridIcon" />
                      <span>{{
                        property.rooms.nb_rooms > 1
                          ? property.rooms.nb_rooms + " pièces"
                          : property.rooms.nb_rooms + " pièce"
                      }}</span>
                    </li>
                  </ul>
                </b-col>
                <b-col md="3" v-if="property.rooms.nb_bed_rooms">
                  <ul class="itemgrid-features list-unstyled my-50">
                    <li>
                      <feather-icon icon="MoonIcon" />
                      <span>{{
                        property.rooms.nb_bed_rooms > 1
                          ? property.rooms.nb_bed_rooms + " chambres"
                          : property.rooms.nb_bed_rooms + " chambre"
                      }}</span>
                    </li>
                  </ul>
                </b-col>
                <b-col
                  md="3"
                  v-if="
                    property.features.balcony === true ||
                    property.features.terrace === true
                  "
                >
                  <ul class="itemgrid-features list-unstyled my-50">
                    <li>
                      <feather-icon icon="TwitterIcon" />
                      <span>Balcon/Terrasse</span>
                    </li>
                  </ul>
                </b-col>
                <b-col md="3" v-if="property.features.elevator === true">
                  <ul class="itemgrid-features list-unstyled my-50">
                    <li>
                      <feather-icon icon="SlidersIcon" />
                      <span>Ascenseur</span>
                    </li>
                  </ul>
                </b-col>
                <b-col md="3" v-if="property.features.garage === true">
                  <ul class="itemgrid-features list-unstyled my-50">
                    <li>
                      <feather-icon icon="TruckIcon" />
                      <span>Garage</span>
                    </li>
                  </ul>
                </b-col>
                <b-col md="3" v-if="property.features.cellar === true">
                  <ul class="itemgrid-features list-unstyled my-50">
                    <li>
                      <feather-icon icon="PackageIcon" />
                      <span>Cellier</span>
                    </li>
                  </ul>
                </b-col>
                <b-col
                  md="3"
                  v-if="
                    property.features.green_spaces === true ||
                    property.features.private_garden === true
                  "
                >
                  <ul class="itemgrid-features list-unstyled my-50">
                    <li>
                      <feather-icon icon="TwitterIcon" />
                      <span>Espace vert</span>
                    </li>
                  </ul>
                </b-col>
              </b-row>

              <b-card-text v-if="property.description.length > 900">
                <span
                  v-if="!readMoreActivated"
                  v-html="property.description.slice(0, 900)"
                ></span>
                <a
                  class=""
                  v-if="!readMoreActivated"
                  @click="activateReadMore"
                  href="#"
                >
                  lire plus...
                </a>
                <span
                  v-if="readMoreActivated"
                  v-html="property.description"
                ></span>
              </b-card-text>

              <b-card-text v-else>
                {{ property.description }}
              </b-card-text>

              <b-card-text>
                <p>Mis en ligne le {{ property.created_at.slice(0, 10) }}</p>
              </b-card-text>

              <hr />

              <b-row>
                <b-col cols="4">
                  <ul class="itemgrid-features list-unstyled my-50">
                    <li v-if="property.contact.name">
                      <feather-icon icon="ShoppingCartIcon" />
                      <span>{{ property.contact.name }}</span>
                    </li>
                    <li v-if="property.contact.city">
                      <feather-icon icon="GlobeIcon" />
                      <span
                        >{{ property.contact.street }}
                        {{ property.contact.zip }}
                        {{ property.contact.city }}
                      </span>
                    </li>
                  </ul>
                </b-col>
                <b-col cols="4">
                  <ul class="itemgrid-features list-unstyled my-50">
                    <li v-if="property.contact.phone">
                      <feather-icon icon="PhoneIcon" />
                      <span>{{ property.contact.phone }}</span>
                    </li>
                    <li v-if="property.contact.email">
                      <feather-icon icon="NavigationIcon" />
                      <span>{{ property.contact.email }}</span>
                    </li>
                  </ul>
                </b-col>
              </b-row>

              <div
                v-if="
                  property.type_parent === 'house' ||
                  property.type_parent === 'apartment'
                "
              >
                <hr />

                <tab-energy :property="property" />
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { currency } from "@core/utils/filter";
import TabEnergy from "./TabEnergy.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,

    TabEnergy,
  },
  data() {
    return {
      readMoreActivated: false,
    };
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  methods: {
    currency,
    imageLoadError() {
      this.property.images = new Array(this.$config.VUE_APP_IMG_404);
    },
    activateReadMore() {
      this.readMoreActivated = true;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-itemgrid-details.scss";

.product-img {
  width: auto;
  height: 42vh;
  object-fit: contain;
}
</style>
