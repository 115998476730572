<template>
  <div>
    <!-- swiper1 -->
    <swiper
      ref="swiperTop"
      class="swiper-gallery gallery-top"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(image, index) in images"
        :key="index"
        style="text-align: center"
      >
        <b-img :src="image" fluid class="img-size-large" />
      </swiper-slide>

      <div slot="button-next" class="swiper-button-next swiper-button-white" />
      <div slot="button-prev" class="swiper-button-prev swiper-button-white" />
    </swiper>

    <!-- swiper2 Thumbs -->
    <swiper
      ref="swiperThumbs"
      class="swiper gallery-thumbs"
      :options="swiperOptionThumbs"
    >
      <swiper-slide v-for="(image, index) in images" :key="index">
        <b-img :src="image" fluid class="img-size-small" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        loop: false,
        loopedSlides: 5,
        spaceBetween: 5,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: false,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 8,
        centeredSlides: true,
        slidesPerView: 6,
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs.$swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/swiper.scss";
</style>

<style scoped>
.img-size-large {
  height: 55vh !important;
}

.img-size-small {
  height: 12rem !important;
}
</style>
