<template>
  <div>
    <b-card
      header="Détails de l'Annonce"
      header-class="font-weight-bolder text-primary h3"
    >
      <!-- Loading Overlay -->
      <b-overlay :show="isBusy" no-wrap opacity="0.4" rounded="lg">
        <template v-slot:overlay>
          <div class="d-flex align-items-center">
            <b-spinner small type="grow" variant="dark" />
            <b-spinner type="grow" variant="primary" />
            <b-spinner small type="grow" variant="dark" />
            <!-- We add an SR only text for screen readers -->
            <span class="sr-only">Chargement wait...</span>
          </div>
        </template>
      </b-overlay>

      <!-- Tabs display -->
      <b-tabs v-if="propertyFetched">
        <b-tab active lazy>
          <template #title>
            <feather-icon icon="AlignCenterIcon" />
            <span>Description</span>
          </template>

          <tab-description :property="propertyFetched" />
        </b-tab>
        <b-tab
          lazy
          v-if="propertyFetched.images && propertyFetched.images.length > 0"
        >
          <template #title>
            <feather-icon icon="CropIcon" />
            <span>Galerie ({{ propertyFetched.images.length }})</span>
          </template>

          <tab-gallery :images="propertyFetched.images" />
        </b-tab>
        <b-tab lazy>
          <template #title>
            <feather-icon icon="ShuffleIcon" />
            <span>Mobilité</span>
          </template>

          <tab-mobility :property="propertyFetched" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import { useRouter } from "@core/utils/utils";
import { BCard, BTabs, BTab, BOverlay, BSpinner } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

// Vue components
import { useRealestateAPI } from "@/views/realestate/demo/searcher/useRealestate";
import realestateStoreModule from "@/views/realestate/demo/realestateStoreModule";
import TabGallery from "./elements/TabGallery.vue";
import TabDescription from "./elements/TabDescription.vue";
import TabMobility from "./elements/TabMobility.vue";

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BOverlay,
    BSpinner,

    // Vue Components
    TabGallery,
    TabDescription,
    TabMobility,
  },
  data() {
    return {
      isBusy: false,
      propertyFetched: null,
    };
  },
  directives: {
    Ripple,
  },
  setup() {
    const REALESTATE_APP_STORE_MODULE_NAME = "app-realestate";

    // Register module
    if (!store.hasModule(REALESTATE_APP_STORE_MODULE_NAME))
      store.registerModule(
        REALESTATE_APP_STORE_MODULE_NAME,
        realestateStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REALESTATE_APP_STORE_MODULE_NAME))
        store.unregisterModule(REALESTATE_APP_STORE_MODULE_NAME);
    });

    const { route } = useRouter();
    const propertyID = route.value.query.propertyID;

    const { getPropertyByID } = useRealestateAPI();

    return {
      getPropertyByID,

      propertyID,
    };
  },
  created() {
    this.fetchPropertyById();

    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "full");
  },
  methods: {
    fetchPropertyById() {
      this.isBusy = true;
      this.getPropertyByID(this.propertyID)
        .then((httpResp) => {
          this.isBusy = false;
          this.propertyFetched = httpResp.data;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>
