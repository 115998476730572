import store from "@/store";
import { ref } from "@vue/composition-api";

// Leaflet
import { latLng, latLngBounds } from "leaflet";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export const useRealestateFiltersAndSorting = () => {
  const filters = ref({
    address: "Lyon",
    distance: 5,
    page: 1,
    perPage: 9,
    numberFilters: 0,
    maxPrice: null,
    minPrice: null,
    searchMode: "buy",
    flowOrigin: null,
    areas: [0, 250],
    rooms: [],
    bedRooms: [],
    types: [],
    category: "both",
    center: latLng(45.761053, 4.846503),
    bounds: latLngBounds([
      [45.80403633840759, 4.909343719482422],
      [45.718168556562276, 4.74935531616211],
    ]),
  });

  const filterOptions = ref({
    distanceOptions: [1, 5, 10, 20, 50],
    searchModeOptions: [
      {
        label: "Acheter",
        value: "buy",
      },
      {
        label: "Louer",
        value: "rent",
      },
    ],
    typesOptions: [
      {
        label: "Maison",
        icon: "HomeIcon",
        value: "house",
      },
      {
        label: "Appartement",
        icon: "TrelloIcon",
        value: "apartment",
      },
      {
        label: "Local/Atelier",
        icon: "ShoppingBagIcon",
        value: "local",
      },
      {
        label: "Terrain",
        icon: "MapIcon",
        value: "field",
      },
      {
        label: "Parking",
        icon: "TruckIcon",
        value: "parking",
      },
    ],
    roomsOptions: [
      {
        label: "1",
        value: "1",
      },
      {
        label: "2",
        value: "2",
      },
      {
        label: "3",
        value: "3",
      },
      {
        label: "4",
        value: "4",
      },
      {
        label: "5+",
        value: "5",
      },
    ],
    categorieOptions: [
      {
        label: "Ancien",
        value: "old",
      },
      {
        label: "Neuf",
        value: "new",
      },
      {
        label: "Ancien & Neuf",
        value: "both",
      },
    ],
    flowOriginOptions: [],
  });

  const fetchFlowsOrigins = () => {
    let queryParams = {
      status: "ENABLED",
    };
    store
      .dispatch("app-realestate/fetchFlowsOrigins", queryParams)
      .then((response) => {
        filterOptions.value.flowOriginOptions = response.data.flows_origins;
      });
  };

  return {
    fetchFlowsOrigins,

    // Filter
    filters,
    filterOptions,
  };
};

export const useRealestateUi = () => {
  const itemView = false;
  const itemViewOptions = [
    { icon: "MapIcon", value: false },
    { icon: "GridIcon", value: true },
  ];

  // Pagination count <= required by pagination component
  const totalProperties = ref({ count: 0 });

  return {
    itemView,
    itemViewOptions,
    totalProperties,
  };
};

export const useRealestateAPI = () => {
  const isBusy = ref(false);
  const properties = ref([]);
  const clusters = ref([]);
  const toast = useToast();

  const fetchSuggestAddress = (query) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-realestate/fetchSuggestAddress", { query })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Adresse introuvable",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const listClusters = (filters) => {
    const searchPropertiesOnMap = {
      min_area: filters.areas[0],
      nb_rooms: filters.rooms.map((room) => Number(room)),
      type_parents: filters.types,
      service: filters.searchMode,
      age: filters.category,
      exclude_invisibles: true,
      geo_bounding_box: [
        JSON.stringify({
          lat: filters.bounds._northEast.lat,
          lng: filters.bounds._northEast.lng,
        }),
        JSON.stringify({
          lat: filters.bounds._southWest.lat,
          lng: filters.bounds._southWest.lng,
        }),
      ],
    };

    // if max area filter on 250m², don't set max area filter
    if (filters.areas[1] < 250) {
      searchPropertiesOnMap.max_area = filters.areas[1];
    }

    filters.flowOrigin
      ? (searchPropertiesOnMap.flow_origin = filters.flowOrigin)
      : null;
    filters.maxPrice
      ? (searchPropertiesOnMap.max_price = Number(filters.maxPrice))
      : null;
    filters.minPrice
      ? (searchPropertiesOnMap.min_price = Number(filters.minPrice))
      : null;

    return new Promise((resolve, reject) => {
      store
        .dispatch("app-realestate/listClusters", searchPropertiesOnMap)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Serveur Realestate indisponible",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const listProperties = (filters) => {
    const searchProperties = {
      page: filters.page,
      page_size: filters.perPage,
      min_area: filters.areas[0],
      nb_rooms: filters.rooms.map((room) => Number(room)),
      type_parents: filters.types,
      service: filters.searchMode,
      age: filters.category,
      geo_bounding_box: [
        JSON.stringify({
          lat: filters.bounds._northEast.lat,
          lng: filters.bounds._northEast.lng,
        }),
        JSON.stringify({
          lat: filters.bounds._southWest.lat,
          lng: filters.bounds._southWest.lng,
        }),
      ],
    };

    // if max area filter on 250m², don't set max area filter
    if (filters.areas[1] < 250) {
      searchProperties.max_area = filters.areas[1];
    }

    filters.flowOrigin
      ? (searchProperties.flow_origin = filters.flowOrigin)
      : null;
    filters.maxPrice
      ? (searchProperties.max_price = Number(filters.maxPrice))
      : null;
    filters.minPrice
      ? (searchProperties.min_price = Number(filters.minPrice))
      : null;

    return new Promise((resolve, reject) => {
      store
        .dispatch("app-realestate/listProperties", searchProperties)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Serveur Realestate indisponible",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const getPropertyByID = (id) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-realestate/getPropertyByID", id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Serveur Realestate indisponible",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const getPropertiesByIDs = (ids) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-realestate/getPropertiesByIDs", ids)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Serveur Realestate indisponible",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  return {
    isBusy,
    properties,
    clusters,

    fetchSuggestAddress,
    listClusters,
    listProperties,
    getPropertyByID,
    getPropertiesByIDs,
  };
};
