<template>
  <div class="item-features">
    <b-row
      class="justify-content-md-center text-center mt-2"
      v-if="
        property.energy.dpe_value_gaz && property.energy.dpe_value_consumption
      "
    >
      <b-col cols="5">
        <energy
          preset="ges"
          :settings="settings"
          v-model="property.energy.dpe_value_gaz"
        ></energy>
      </b-col>
      <b-col cols="5">
        <energy
          preset="dpe"
          :settings="settings"
          v-model="property.energy.dpe_value_consumption"
        ></energy>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center text-center mb-1 mt-2" v-else>
      <b-col>
        <feather-icon icon="LoaderIcon" size="30" />
        <h4 class="mt-2 mb-1">DPE & GSE</h4>
        <p class="card-text">
          Bilan de consommation énergétique non disponnible.
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import Energy from "./energy/Energy.vue";

export default {
  components: {
    BRow,
    BCol,

    Energy,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      settings: [
        {
          color: "#f6eefd",
          letter: "A",
          legend: "<= 5",
          isValid: (i) => i <= 5,
        },
        {
          color: "#e0c2f8",
          letter: "B",
          legend: "6 to 10",
          isValid: (i) => i >= 6 && i <= 10,
        },
        {
          color: "#d4aaf6",
          letter: "C",
          legend: "11 to 20",
          isValid: (i) => i >= 11 && i <= 20,
        },
        {
          color: "#cb95f3",
          letter: "D",
          legend: "21 to 35",
          isValid: (i) => i >= 21 && i <= 35,
        },
        {
          color: "#ba72ef",
          letter: "E",
          legend: "36 to 55",
          isValid: (i) => i >= 36 && i <= 55,
        },
        {
          color: "#a74deb",
          letter: "F",
          legend: "56 to 80",
          isValid: (i) => i >= 56 && i <= 80,
        },
        {
          color: "#8919df",
          text_color: "#fff",
          letter: "G",
          legend: "> 80",
          isValid: (i) => i > 80,
        },
      ],
    };
  },
};
</script>

<style scoped>
.item-features {
  background-color: #ffff !important;
  padding-top: 0rem !important;
  padding-bottom: 2rem !important;
}
</style>
