<template>
  <b-row>
    <b-col cols="9">
      <div id="map"></div>
      <div class="legend">
        <div class="container"></div>
      </div>
    </b-col>
    <b-col cols="3">
      <b-card title="Sélection de l'isochrone">
        <b-overlay :show="isBusy" no-wrap opacity="0.4" rounded="lg">
          <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="dark" />
              <b-spinner type="grow" variant="primary" />
              <b-spinner small type="grow" variant="dark" />
              <!-- We add an SR only text for screen readers -->
              <span class="sr-only">Chargement wait...</span>
            </div>
          </template>
        </b-overlay>

        <b-form-radio-group
          v-model="filters.value.travelType"
          :options="optionsFilter.value.travelType"
          value-field="value"
          text-field="label"
          size="lg"
          class="mx-2"
          stacked
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import * as tgm from "@targomo/core";
import * as tgmLeaflet from "@targomo/leaflet";

import { ref } from "@vue/composition-api";
import {
  BCard,
  BRow,
  BCol,
  BFormRadioGroup,
  BOverlay,
  BSpinner,
} from "bootstrap-vue";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// eslint-disable-next-line no-underscore-dangle
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
/* eslint-disable global-require */

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormRadioGroup,
    BOverlay,
    BSpinner,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      clientTargomo: new tgm.TargomoClient(
        "westcentraleurope",
        this.$config.VUE_APP_TARGOMO_API_KEY
      ),
      map: null,
      polygonOverlayLayer: null,
      filters: ref({
        travelType: "walk",
        travelEdgeWeights: [300, 600, 900],
        maxEdgeWeight: 900,
        edgeWeight: "time",
        serializer: "json",
      }),
      optionsFilter: ref({
        travelType: [
          {
            label: "Voiture",
            value: "car",
          },
          {
            label: "Vélo",
            value: "bike",
          },
          {
            label: "Marche",
            value: "walk",
          },
          {
            label: "Transport public",
            value: "public transport",
          },
        ],
      }),
    };
  },
  watch: {
    "filters.value.travelType"() {
      this.fetchIsochrones();
    },
  },
  setup(props) {
    const center = [
      props.property.address.location.lat,
      props.property.address.location.lng,
    ];
    return {
      center,
    };
  },
  mounted() {
    this.initMap();
    this.fetchIsochrones();
  },
  methods: {
    initMap() {
      // define the basemap
      const tileLayer = L.tileLayer(this.$config.VUE_APP_MAP_TILE_URL);

      // define the map
      const map = L.map("map", {
        layers: [tileLayer],
      }).setView(this.center, 11);

      // set the attribution
      const attributionText =
        '<a href="https://www.targomo.com/developers/resources/attribution/" target="_blank">&copy; Targomo</a>';
      map.attributionControl.addAttribution(attributionText);

      // define the polygon overlay
      const polygonOverlayLayer = new tgmLeaflet.TgmLeafletPolygonOverlay({
        strokeWidth: 20,
      });
      polygonOverlayLayer.addTo(map);

      // iterate the colors assigned to the polygons to build legend
      Object.keys(polygonOverlayLayer.options.colors).forEach((time) => {
        if (time < 1000) {
          const color = polygonOverlayLayer.options.colors[time];
          const label = `${time / 60}min`;
          const target = document.querySelector(".container");
          const cell = document.createElement("div");
          cell.classList.add("cell");
          cell.style.backgroundColor = color;
          cell.innerText = label;
          target.appendChild(cell);
        }
      });

      this.map = map;
      this.polygonOverlayLayer = polygonOverlayLayer;
    },
    fetchIsochrones() {
      this.isBusy = true;

      // define the starting point
      const sources = [{ id: 0, lat: this.center[0], lng: this.center[1] }];

      // Add markers for the sources on the map.
      sources.forEach((source) => {
        L.marker([source.lat, source.lng]).addTo(this.map);
      });

      // get the polygons
      this.clientTargomo.polygons
        .fetch(sources, this.filters.value)
        .then((polygons) => {
          // calculate bounding box for polygons
          const bounds = polygons.getMaxBounds();
          // add polygons to overlay
          this.polygonOverlayLayer.setData(polygons);
          // zoom to the polygon bounds
          this.map.fitBounds(
            new L.latLngBounds(bounds.northEast, bounds.southWest)
          );
          this.isBusy = false;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";

.leaflet-container {
  height: 63vh;
}
</style>

<style>
.legend {
  position: relative;
  left: 1%;
  bottom: 6%;
  width: 100%;
  z-index: 1000;
}

.legend .container {
  display: flex;
}

.legend .container .cell {
  text-align: center;
  padding: 10px 0;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  min-width: 60px;
}
</style>
